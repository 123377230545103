import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueImg from 'v-img';
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueImg);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAL-tOcgf6RhoOOpJW4EF2EnWJN8TKVS50",
    libraries: "places" // necessary for places input
  }
});

Vue.config.productionTip = false
Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: 'https://admin.itsyourdayofficial.com/api/'
    })
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
